import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BotLogo from '../../assets/BotLogo';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'rgb(189,189,189)',
        maxHeight: '64px'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: { marginRight: 36 },
    hide: { display: 'none' }
}));

const TopAppBar = ({ open, setOpen }) => {
    const classes = useStyles();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (        
        <AppBar
            position='fixed'
            className={clsx(classes.appBar, { [classes.appBarShift]: open })}
        >
            <Toolbar>
                <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    onClick={handleDrawerOpen}
                    edge='start'
                    className={clsx(classes.menuButton, { [classes.hide]: open })}
                >
                    <FontAwesomeIcon className='sidebar-menu-icon' icon={['fal', 'bars']} size='lg' />
                </IconButton>
                <div className='app-bar-text__container'>
                    <BotLogo className='sidebar-logo' />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar;