import gql from 'graphql-tag';

const CREATE_PROGRAM = gql`
	mutation AddProgram($program: NewProgram) {
		addProgram(program: $program) {
			success
			statusCode
			message
			data {
				id
				title
				pid
				tag
				description
				avatar  
			}
		}
	}
`;

const UPDATE_PROGRAM = gql`
	mutation UpdateProgram($id: ID, $program: UpdateProgram) {
		updateProgram(id: $id program: $program) {
			success
			statusCode
			message
			data {
				id
				title
				pid
				tag
				description
				avatar      
			}
		}
	}
`;

const DELETE_PROGRAM = gql`
	mutation DeleteProgram($id: ID) {
		deleteProgram(id: $id) {
			success
			statusCode
			message
			data {
				id
				title
				pid
				tag
				description
				avatar      
			}
		}
	}
`;

const CREATE_OFFER = gql`
	mutation AddOffer($id: ID, $route: String, $offer: NewOffer) {
		addOffer(id: $id, route: $route, offer: $offer) {
			success
			statusCode
			message
			data {
				id
				title
				route
				layout {
					title
					image
					didYouKnow
					text
				}
				link
				jumpBehind
				click_count
				usage 
			}
		}
	}
`;

const UPDATE_OFFER = gql`
	mutation UpdateOffer($id: ID, $route: String, $offer: UpdateOffer) {
		updateOffer(id: $id, route: $route, offer: $offer) {
			success
			statusCode
			message
			data {
        		id
				title
				route
				layout {
					title
					image
					didYouKnow
					text
				}
				link
				jumpBehind
				click_count
				usage  
			}
		}
	}
`;

const DELETE_OFFER = gql`
	mutation DeleteOffer($id: ID, $route: String, $offerID: String) {
		deleteOffer(id: $id, route: $route, offerID: $offerID) {
			success
			statusCode
			message
			data {
        		id
				title
				route
				layout {
					title
					image
					didYouKnow
					text
				}
				link
				jumpBehind
				click_count
				usage  
			}
		}
	}
`;

export {
	CREATE_PROGRAM,
	UPDATE_PROGRAM,
	DELETE_PROGRAM,
	CREATE_OFFER,
	UPDATE_OFFER,
	DELETE_OFFER,
};