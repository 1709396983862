import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AppContextProvider } from './context';
import { Auth0Provider } from './utilities/react-auth0-spa';
import { ApolloProvider } from '@apollo/client';
import { ToastProvider } from 'react-toast-notifications';
import useApolloClient from './utilities/hooks/useApolloClient';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import App from './components/App';

import './stylesheets/main.scss';

// fontawesome
import initFontAwesome from './utilities/initFontAwesome';
initFontAwesome();

const history = createBrowserHistory();

const GlobalApp = () => {

	const apolloUri = 'https://mfzgtl2zoj.execute-api.us-east-1.amazonaws.com/prod/graphql';
	// const apolloUri = 'https://3um9v9wuv7.execute-api.us-east-1.amazonaws.com/active/graphql';
	// const apolloUri = 'https://7cv2781k2a.execute-api.us-east-1.amazonaws.com/staging/graphql';
	// const apolloUri = 'https://k85tojwt8i.execute-api.us-east-1.amazonaws.com/test/graphql';
	// const apolloUri = 'http://localhost:4000/dev/graphql';

	const client = useApolloClient(apolloUri);

	const onRedirectCallback = appState => {
		history.push(
			appState && appState.targetUrl ?
			appState.targetUrl :
			window.location.pathname
		);
	};

	return (
		<Router history={ history }>
			<ApolloProvider client={client}>
				<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN}
				client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
				redirect_uri={window.location.origin}
				onRedirectCallback={onRedirectCallback}
				>
					<AppContextProvider>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<ToastProvider placement='bottom-right'>
								<App />
							</ToastProvider>
						</LocalizationProvider>
					</AppContextProvider>
				</Auth0Provider>
			</ApolloProvider>
		</Router>
	);
}

ReactDOM.render(<GlobalApp />, document.getElementById('root'));

