import 'date-fns';
import React, { useState } from 'react';
import styles from './Health.css.js';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import Box from '@mui/material/Box';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useQuery } from '@apollo/client';
import { CONVERSION_REPORT } from '../../../utilities/queries';
import MarketingSelectors from '../../MarketingSelectors';
import dayjs from 'dayjs';

const bucketSizes = [ 5, 10, 25, 50, 100, 250, 500, 1000];
const months = ['01','02','03','04','05','06','07','08','09','10','11','12'];

const getLastWeekDate = () => {
    const today = dayjs();
    const year = today.get('year');
    const month = today.get('month');
    const day = today.get('date');
    return `${year}-${months[month]}-${day - 6}`;
};
const getTomorrowsDate = () => {
    const today = dayjs();
    const year = today.get('year');
    const month = today.get('month');
    const day = today.get('date');
    return `${year}-${months[month]}-${day + 1}`;
};

const cleanDate = (newDate) => {
    const date = dayjs(newDate);
    const year = date.get('year');
    const month = date.get('month');
    const day = date.get('date');
    return `${year}-${months[month]}-${day}`;
};

const ConversionGraph = ({ provider, startSize }) => {
    const lastWeek = getLastWeekDate();
    const tomorrow = getTomorrowsDate();
    const [value, setValue] = useState([dayjs().subtract(6, 'day'), dayjs().add(1, 'day')]);
    const [startDate, setStartDate] = useState(lastWeek);
    const [endDate, setEndDate] = useState(tomorrow);
    const [bucketSize, setBucketSize] = useState(startSize);
    const [pidValue, setPidValue] = useState('ALL');
    const [sidValue, setSidValue] = useState('ALL');
    const [oidValue, setOidValue] = useState('ALL');

    const { loading, error, data } = useQuery(
		CONVERSION_REPORT, { variables: { 
                provider, 
                dateStart: startDate,
                dateEnd: endDate,
                bucketSize: bucketSize,
                program: {
                    pid: pidValue,
                    sid: sidValue,
                    oid: oidValue
                }
            }
        }
    );
    const handleSizeChange = (event) => {
        setBucketSize(event.target.value);
    };

    const handleChange = (date) => {
        const setDate1 = cleanDate(date[0]);
        const setDate2 = cleanDate(date[1]);
        setStartDate(setDate1);
        setEndDate(setDate2);
        setValue(date);
        console.log('start:', setDate1);
        console.log('end:', setDate2);
    };

    if(loading) {
        return <div>Loading...</div>
    };
    if(error) {
        console.error(error);
        return <div>!!!Error!!!</div>
    };
    // if(data) {
    //     console.log('data:', data.fetchConversionRates.data);
    // }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={styles.tooltip}>
                    <p style={styles.tooltipData}>{`Bucket : ${payload[0].payload.bucketIdx}`}</p>
                    <p style={styles.tooltipData}>{`Start : ${payload[0].payload.start}`}</p>
                    <p style={styles.tooltipData}>{`End : ${payload[0].payload.end}`}</p>
                    <p style={styles.tooltipData}>{`Conversion Rate : ${payload[0].payload.conversionRate}`}</p>
                </div>
            );
        }
        
        return null;
    };

    const graphColor = () => {
        switch(provider) {
            case 'acima':
                return 'url(#colorAcima)';
            case 'westcreek':
                return 'url(#colorWc)';
            case 'aff':
                return 'url(#colorAff)';
            default:
                return 'url(#colorAcima)'
        };
    };
    return (
        <>        
            <div style={styles.providerBar}>
                <div style={styles.providerTitle}>{provider}</div>
                <div style={styles.dateInputs}>
                    <Grid container direction="column" alignItems="flex-start">
                        <DateRangePicker
                        calendars={1}
                        value={value}
                        onAccept={(newValue) => { handleChange(newValue) }}
                        />
                    </Grid>
                </div>
                <MarketingSelectors 
                    pidValue={pidValue} 
                    setPidValue={setPidValue}
                    sidValue={sidValue} 
                    setSidValue={setSidValue}
                    oidValue={oidValue} 
                    setOidValue={setOidValue}
                />
                <TextField
                    id='bucket-size'
                    label='Bucket Size'
                    select
                    value={bucketSize}
                    onChange={handleSizeChange}
                    variant='filled'
                    style={styles.bucketInput}
                >
                    {bucketSizes.map((option) => (
                        <MenuItem key={`${option}-bucket`} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div style={styles.graphContainer}>
                <ResponsiveContainer width='100%' height='100%'>
                    <AreaChart
                        width={730} height={250} data={data.fetchConversionRatesByProgram.data}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                    <defs>
                        <linearGradient id='colorAcima' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8}/>
                            <stop offset='95%' stopColor='#8884d8' stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id='colorWc' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#82ca9d' stopOpacity={0.8}/>
                            <stop offset='95%' stopColor='#82ca9d' stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id='colorAff' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#31ACC0' stopOpacity={0.8}/>
                            <stop offset='95%' stopColor='#31ACC0' stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='date'/>
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Area type='monotone' dataKey='conversionRate' stroke='#8884d8' fillOpacity={1} fill={graphColor()} />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </>
    )
};

export default ConversionGraph;