import React from 'react';
import { useAuth0 } from '../utilities/react-auth0-spa';
import Routes from '../Routes';
import Sidebars from './Sidebars';
import Loading from './Loading';
import { LicenseInfo } from '@mui/x-license-pro';

const App = () => {
	const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

	if (loading) {
        return (
            <div className='loading-container'>
                <Loading />
            </div>
        );
	};

	if(!isAuthenticated) {        
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: window.location.pathname }
            });
        };
        fn();
        return <div style={{width: '100vw', height: '100vh', backgroundColor: '#10406B'}} />
    };
	
	return (
		<div className='app-container'>
			<Sidebars>
				<Routes />				
			</Sidebars>
		</div>
	);
}

export default App;