import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './Providers.css.js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery } from '@apollo/client';
import { SET_PROVIDER_LIST } from './graphql/SET_PROVIDER_LIST';
import { FETCH_PROVIDERS } from './graphql/FETCH_PROVIDERS';

const SaveButton = ({ handleSubmission, isSaving }) => {
    const [ isHovering, setHovering ] = useState(false);

    const themedStyle = Object.assign({},
        styles.submitButton,
        isHovering && styles.hover,
        isSaving && styles.progress
    );

    return(
        <Button 
            type='submit'
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            style={themedStyle}
            onClick={handleSubmission}
            onFocus={(event) => event.stopPropagation()}
        >
            {isSaving ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'Save'}
        </Button>
    )
};

const Providers = () => {
    const { addToast } = useToasts();
    const matches = useMediaQuery('(min-width:600px)');

    const [ isSaving, setIsSaving ] = useState(false);
    const handleRedirect = (data) => {
        console.log('data:', data.setProviderSelection);
        addToast(data.setProviderSelection.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
    };
    const handleError = (error) => {
        console.error(error);
        addToast(`SAVE Provider update: FAIL. ${error.message}`, { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
    };

    const { loading, error, data } = useQuery(FETCH_PROVIDERS);

    const { register, handleSubmit } = useForm({ 
        mode: 'onChange', 
        reValidateMode: 'onChange',
        defaultValues: {
            acima: '',
            westcreek: '',
            aff: '',
            flex: '',
            paytomorrow: '',
            terrace: '',
            uown: ''
        },
        values: data?.fetchProviderSelection?.data
    });

    
    const [saveProviderList] = useMutation(
        SET_PROVIDER_LIST, {
            refetchQueries: [{ query: FETCH_PROVIDERS }],
            onCompleted: handleRedirect,
            onError: handleError
        }
    );
    
    const handleSubmission = (data) => {
        setIsSaving(true);
        const body = {
            acima: Number(data.acima),
            westcreek: Number(data.westcreek),
            aff: Number(data.aff),
            flex: Number(data.flex),
            paytomorrow: Number(data.paytomorrow),
            terrace: Number(data.terrace),
            uown: Number(data.uown),
        };
        saveProviderList({ variables: { providerList: body }});
    };

    if (error) return <div>{`Error occured fetching providerList: ${error.message}`}</div>;

    const acimaInput = register('acima', { required: true });
    const westcreekInput = register('westcreek', { required: true });
    const affInput = register('aff', { required: true });
    const flexInput = register('flex', { required: true });
    const paytomorrowInput = register('paytomorrow', { required: true });
    const terraceInput = register('terrace', { required: true });
    const uownInput = register('uown', { required: true });

    const ImageStyle = Object.assign({},
        matches ? styles.providerImg : styles.providerImgSm
    );

    return (
        <div style={styles.main}>
            <div style={styles.headerBar}>
                <div style={styles.title}>Lease Providers</div>
            </div>
            {!loading && !!data?.fetchProviderSelection?.data ? 
            <form style={styles.providerList}>
                <div style={styles.leaseProvider}>
                    <img alt='acima' src='https://cdn.shopify.com/s/files/1/1353/6343/files/acima_logo.png?v=1605297314' style={{...ImageStyle, padding: '0 10px' }} />
                    <TextField
                        name='acima'
                        label='%'
                        style={styles.valueInput}
                        inputRef={acimaInput.ref}
                        onChange={acimaInput.onChange}
                    />
                </div>
                <div style={styles.leaseProvider}>
                    <img alt='westcreek' src='https://westcreekfin.com/wp-content/uploads/2019/06/Logo.png' style={{...ImageStyle, backgroundColor: 'rgb(34,38,94)'}} />
                    <TextField
                        name='westcreek'
                        label='%'
                        style={styles.valueInput}
                        inputRef={westcreekInput.ref}
                        onChange={westcreekInput.onChange}
                    />
                </div>
                <div style={styles.leaseProvider}>
                    <img alt='aff' src='https://streetcredcapital.com/wp-content/uploads/2020/03/AFF_2019_Logo_Stacked_Color.png' style={{...ImageStyle, backgroundColor: 'rgba(34,38,94,0.25)'}} />
                    <TextField
                        name='aff'
                        label='%'
                        style={styles.valueInput}
                        inputRef={affInput.ref}
                        onChange={affInput.onChange}
                    />
                </div>
                <div style={styles.leaseProvider}>
                    <img alt='flex' src='https://www.sec.gov/Archives/edgar/data/1397047/000121390019012114/ex99-1_img001.jpg' style={{...ImageStyle, backgroundColor: 'rgba(34,38,94,0.25)'}} />
                    <TextField
                        name='flex'
                        label='%'
                        style={styles.valueInput}
                        inputRef={flexInput.ref}
                        onChange={flexInput.onChange}
                    />
                </div>
                <div style={styles.leaseProvider}>
                    <img alt='paytomorrow' src='https://ecom.paytomorrow.com/assets/images/logos/PayTomorrow_Logo_light_500px_150px.png' style={{...ImageStyle, padding: '0'}} />
                    <TextField
                        name='paytomorrow'
                        label='%'
                        style={styles.valueInput}
                        inputRef={paytomorrowInput.ref}
                        onChange={paytomorrowInput.onChange}
                    />
                </div>
                <div style={styles.leaseProvider}>
                    <img alt='terrace' src='https://cdnmedia.endeavorsuite.com/images/ThumbGenerator/Thumb.aspx?img=%2F%2Fcdnmedia.endeavorsuite.com%2Fimages%2Forganizations%2F68a05931-f9a3-4b1f-9002-1cdeb8594fc4%2FLogos%2FTerrace+Finance.png&v=1584035998673&w=500' style={{...ImageStyle, padding: '0'}} />
                    <TextField
                        name='terrace'
                        label='%'
                        style={styles.valueInput}
                        inputRef={terraceInput.ref}
                        onChange={terraceInput.onChange}
                    />
                </div>
                <div style={styles.leaseProvider}>
                    <img alt='uown' src='https://uown-stage1-dd.rightandabove.com/logos/uown-logo.svg' style={{...ImageStyle, padding: '5px 0'}} />
                    <TextField
                        name='uown'
                        label='%'
                        style={styles.valueInput}
                        inputRef={uownInput.ref}
                        onChange={uownInput.onChange}
                    />
                </div>
                <SaveButton handleSubmission={handleSubmit(handleSubmission)} isSaving={isSaving}/>
            </form>
            :
            <div style={styles.loadingProviders}><CircularProgress size={64} style={styles.buttonProgress} /></div>
            }
        </div>
    )
};

export default Providers;