const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        paddingLeft: '10px'
        // padding: '0 10px'
        // padding: '10px'
    },
    headerBar: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        borderBottom: 'solid 1px rgb(0, 0, 0)',
        marginTop: '30px',
    },
    mobileHeaderBar: {
        margin: '10px 0',
        width: '100%'
    },
    title: {
        fontSize: '2rem',
        paddingLeft: '20px',
        fontWeight: '200',
    },
    mobileTitle: {
        fontSize: '1.5rem',
        paddingLeft: '5px',
        fontWeight: '300',
    },
    inputRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        marginTop: '20px'
    },
    mobileInputRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        margin: '10px 0',
        borderBottom: 'solid 1px rgb(0, 0, 0)',
    },
    tableContainer: {
        padding: '0 20px',
        width: '100%'
    },
    mobileTableContainer: {
        padding: '0'
    },
    providerList: {
        // margin: '20px',
        width: '100%',
    },
    leaseProvider: {
        width: '100%',
        display: 'inline-flex',
        maxHeight: '60px',
        marginTop: '20px',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    imgContainer: {
        minWidth: '300px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    // imgContainer: {

    // },
    providerImg: {
        // width: '250px',
        // minWidth: '250px',
        padding: '20px',
        borderRadius: '5px',
        // marginTop: '20px',
        maxHeight: '60px'
    },
    providerImgSm: {
        // width: '150px',
        maxHeight: '30px',
        padding: '5px',
        borderRadius: '5px',
        // marginTop: '10px'
    },
    dataRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between'
    },
    dataRowItem: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'center'
    },
    tableHeader: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        borderBottom: '1px solid black',
        marginTop:'40px'
    },
    imageHeader: { minWidth: '300px' },
    imageHeaderSm: { minWidth: '150px' },
    headerTexts: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: '10%'
    },
    mobileProvider: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        margin: '40px 0',
        backgroundColor: 'rgb(239,239,239)',
        width: '100%'
    },
    mobileLogoRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    mobileTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    mobileDataRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0,0,0,0.5)',
    },
    mobileKey: {
        fontWeight: 'bold',
        color: 'rgba(0,0,0,0.75)',
        fontSize: '1rem',
        paddingLeft: '10px'
    },
    mobileValue: {
        marginLeft: '20px',
        paddingRight: '10px',
        fontSize: '1.1rem',
        fontWeight: 'bold',
    }
};

export default styles;