import React, { createContext, useReducer } from 'react';

import { initialAppState, appStateReducer } from '../reducers/appReducer';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
    // Reducers
	const [appState, dispatchApp] = useReducer(appStateReducer, initialAppState);

	const defaultContext = {
        appState, 
        dispatchApp
	};
	
	return (
        <AppContext.Provider value={defaultContext}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppContextProvider };