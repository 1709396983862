import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faHome, 
  faChartNetwork,
  faArrowToRight,
  faSignOut,
  faPlusCircle,
  faTrashAlt,
  faMinusCircle,
  faTools,
  faFileChartLine,
  faHourglass,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
  faInfinity,
  faChevronLeft,
  faBars,
  faTachometerAltFastest,
  faEye,
  faThumbsUp,
  faHandsUsd,
  faFileSignature,
  faHeartRate
} from '@fortawesome/pro-light-svg-icons';

function initFontAwesome() {
  library.add(
	faHome,
	faChartNetwork,
  faArrowToRight,
  faSignOut,
  faPlusCircle,
  faMinusCircle,
  faTrashAlt,
  faTools,
  faFileChartLine,
  faHourglass,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
  faInfinity,
  faChevronLeft,
  faBars,
  faTachometerAltFastest,
  faEye,
  faThumbsUp,
  faHandsUsd,
  faFileSignature,
  faHeartRate
    );
}

export default initFontAwesome;
