import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { MARKETING_SUMMARY } from '../../utilities/queries';
import styles from './MarketingSelectors.css.js';
import Selector from './Selector';
import { useMediaQuery } from 'react-responsive';

const MarketingSelectors = ({pidValue, setPidValue, sidValue, setSidValue, oidValue, setOidValue}) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [ choiceData, setData ] = useState(null);
    const { loading, error, data } = useQuery(MARKETING_SUMMARY);

    useEffect(() => {
        if(data) {
            setData(data.fetchAllMarketingPoints.data);
        }
    },[data]);

    const handlePidChange = (event) => {
        setPidValue(event.target.value);
    };
    const handleSidChange = (event) => {
        setSidValue(event.target.value);
    };
    const handleOidChange = (event) => {
        setOidValue(event.target.value);
    };

    if(loading || !choiceData) {
        return <div>Loading...</div>
    };
    if(error) {
        console.error(error);
        return <div>!!!Error!!!</div>
    };

    const mainStyle = Object.assign({},
        styles.mainContainer,
        isMobile && styles.mobileMainContainer
    );

    return (
        <div style={mainStyle}>
            <Selector identity='pid' choices={['ALL', ...choiceData.pid]} value={pidValue} handleChange={handlePidChange}/>
            <Selector identity='sid' choices={['ALL', ...choiceData.sid]} value={sidValue} handleChange={handleSidChange}/>
            <Selector identity='oid' choices={['ALL', ...choiceData.oid]} value={oidValue} handleChange={handleOidChange}/>
        </div>
    )
};

export default MarketingSelectors;