import React from 'react';
import { Switch } from 'react-router-dom';
import Welcome from '../components/pages/Welcome';
import Providers from '../components/pages/Providers';
import Reports from '../components/pages/Reports';
import Health from '../components/pages/Health';
import AddProgram from '../components/pages/AddProgram';
import EditProgram from '../components/pages/EditProgram';
import AddOffer from '../components/pages/AddOffer';
import EditOffer from '../components/pages/EditOffer';
import PrivateRoute from './PrivateRoute';

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path='/' exact component={Welcome} />
      <PrivateRoute path='/providers' component={Providers} />
      <PrivateRoute path='/reports' component={Reports} />
      <PrivateRoute path='/health' component={Health} />
      <PrivateRoute path='/add_program' component={AddProgram} />
      <PrivateRoute path='/edit_program' component={EditProgram} />
      <PrivateRoute path='/add_offer' component={AddOffer} />
      <PrivateRoute path='/edit_offer' component={EditOffer} />
    </Switch>
  );
};

export default Routes;