import React, { useContext } from 'react';
import { AppContext } from '../../context';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UPDATE_OFFER, DELETE_OFFER } from '../../utilities/mutations';
import { OFFERS } from '../../utilities/queries';
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

const EditOffer = () => {
	const { appState, dispatchApp } = useContext(AppContext);
	const { addToast } = useToasts();
	let history = useHistory();

	const handleRedirect = (data) => {
		if (data.updateOffer && data.updateOffer.success) {
			addToast('Successfully UPDATED offer!', { appearance: 'success', autoDismiss: true });
			history.push('/offers');
			return;
		}
		if (data.deleteOffer && data.deleteOffer.success) {
			addToast('Successfully DELETED offer!', { appearance: 'success', autoDismiss: true });
			history.push('/offers');
			return;
		}
		addToast('Failed to EDIT offer!', { appearance: 'error', autoDismiss: true });
		history.push('/offers');
	};

	const [updateOffer] = useMutation(
		UPDATE_OFFER,
		{ onCompleted: handleRedirect }
	);

	const [deleteOffer] = useMutation(
		DELETE_OFFER,
		{ onCompleted: handleRedirect }
	);

	const handleDelete = (e) => {
		e.preventDefault();
		deleteOffer({
			refetchQueries: [{
				query: OFFERS,
				variables: { 
					id: appState.programId,
					route: appState.addToRoute
				}
			}],
			variables: { 
				id: appState.programId,
				route: appState.addToRoute,
				offerID: appState.activeOffer.id
			} 
		});
		dispatchApp({ type: 'HIDE_EDIT_PROGRAM' });
		history.push('/offers');
	};

	const handleCancel = (e) => {
		e.preventDefault();
		dispatchApp({ type: 'HIDE_EDIT_OFFER' })
		history.push('/offers');
	};

	const handleSubmit = (e) => {
		const { title, layout, didYouKnow, text, link, jumpBehind, usage } = e.currentTarget.elements;
		e.preventDefault();
	
		const payload = {
			id: appState.programId,
			route: appState.addToRoute,
			offer: {
				id: appState.activeOffer.id,
				title: title.value || 'N/A',
				route: appState.addToRoute,
				layout: {
					title: layout.value,
					didYouKnow: didYouKnow.value || 'N/A',
					text: text.value || 'N/A'
				},
				link: link.value || 'https://buyontrust.info/',
				jumpBehind: jumpBehind.value || 'N/A',
				click_count: 0,
				usage: Number(usage.value) || 0
			}
		};
		updateOffer({
			refetchQueries: [{
				query: OFFERS,
				variables: { 
					id: appState.programId,
					route: appState.addToRoute
				}
			}],
			variables: payload 
		});
	};
	
	if(!appState.activeOffer) {
		history.push('/offers');
		return
	};

	const { title, layout, link, jumpBehind, usage } = appState.activeOffer;
	const layoutName = layout.title;
	const didYouKnow = layout.didYouKnow;
	const text = layout.text;

	return (
		<div className='mutation__container'>
			<div className='mutation__title'>
				<div className='offers-title-text-main'>{`Edit Offer ${title}:`}</div>
			</div>
			<form className='form__card' onSubmit={(e) => handleSubmit(e)}>
				<TextField 
					name='title'
					className='form__text-input'
					label='Title'
					fullWidth
					margin='normal'
					defaultValue={title}
				/>				
				<TextField 
					name='layout' 
					className='form__text-input'
					label='Page Layout'
					fullWidth
					margin='normal'
					defaultValue={layoutName}
				/>
				<TextField 
					name='didYouKnow' 
					className='form__text-input'
					label='Did-You-Know text'
					fullWidth
					margin='normal'
					defaultValue={didYouKnow}
				/>
				<TextField 
					name='text' 
					className='form__text-input'
					label='Description text'
					fullWidth
					margin='normal'
					defaultValue={text}
				/>
				<TextField 
					name='link' 
					className='form__text-input'
					label='Link URL'
					fullWidth
					margin='normal'
					defaultValue={link}
				/>
				<TextField 
					name='jumpBehind'
					className='form__text-input'
					label='Jump Behind URL'
					fullWidth
					margin='normal'
					defaultValue={jumpBehind}
				/>
				<TextField 
					name='usage'
					className='form__text-input'
					label='% Usage'
					fullWidth
					margin='normal'
					defaultValue={usage}
				/>
				<ButtonGroup className='form__buttons'>
					<Button onClick={(e) => handleDelete(e)} variant='contained' color='secondary'>Delete</Button>			
					<Button onClick={(e) => handleCancel(e)}>Cancel</Button>
					<Button type='submit' size='large' variant='contained' color='primary'>Save</Button>
				</ButtonGroup>
			</form>
		</div>
	);
}

export default EditOffer;