import gql from 'graphql-tag';

export const FETCH_PROVIDERS = gql`
    query{
        fetchProviderSelection {
            success
            statusCode
            message
            data {
                acima
                westcreek
				aff
				flex
				paytomorrow
				terrace
				uown
            }
        }
    }
`;