import React from 'react';
import styles from './Health.css.js';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import ConversionGraph from './ConversionGraph';
// import ProviderGraph from './ProviderGraph.jsx';

const Health = () => {
    return (
        <div style={styles.main}>
            <div style={styles.headerBar}>
                <div style={styles.title}>Provider Health</div>
            </div>
            <div style={styles.providerContainer}>
                <ConversionGraph provider='acima' startSize={250}/>
                {/* <ConversionGraph provider='westcreek' startSize={250}/> */}
                {/* <ConversionGraph provider='flex' startSize={250}/> */}
                {/* <ConversionGraph provider='aff' startSize={250}/> */}
                {/* <ProviderGraph provider='aff' startSize={250}/> */}
            </div>
        </div>
    )
};

export default Health;