import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '../utilities/react-auth0-spa';

const PrivateRoute = ({ component: Component, path }) => {
	const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

	useEffect(() => {
		let mounted = true;
		if (loading || isAuthenticated) {
			return;
		};

		const fn = async () => {
			if(mounted) {
				await loginWithRedirect({
					appState: { targetUrl: path }
				});
			}
		};
		fn();
		// Cleanup function
		return () => mounted = false;
	}, [loading, isAuthenticated, loginWithRedirect, path]);
	return isAuthenticated === true ? <Component /> : null;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default PrivateRoute;