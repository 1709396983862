import gql from 'graphql-tag';

const FETCH_FULL_REPORT = gql`
    query fetchFullReport($start: String, $end: String, $provider: String, $program: ProgramInput) {
        fetchFullReport(start: $start, end: $end, provider: $provider, program: $program) {
            success
            statusCode
            message
            data {
                abandoned
                amount
                approved
                declined
                provider
                starts
            }
        }
    }
`;

export {
    FETCH_FULL_REPORT
}