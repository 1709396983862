import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth0 } from '../../utilities/react-auth0-spa';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useHistory } from 'react-router-dom';

const DrawerItems = () => {
    let history = useHistory();
    const { logout } = useAuth0();
    const componentIsMounted = useRef(true);

    useEffect(() => {
        if (componentIsMounted.current) {
            let path = window.location.pathname;
            switch (path) {
                case '/providers':
                    history.push('/providers');
                    return;

                case '/reports':
                    history.push('/reports');
                    return;
                
                case '/health':
                    history.push('/health');
                    return;

                default:
                    return;
            };
        }

        return () => componentIsMounted.current = false;
        // eslint-disable-next-line
    }, []);

    const switchActiveItem = (e, tab) => {
        e.preventDefault();
        switch (tab) {
            case 'providers':
                history.push('/providers');
                return;

            case 'reports':
                history.push('/reports');
                return;

            case 'health':
                history.push('/health');
                return;

            default:
                return;
        }
    };

    return (
        <div className='drawer-items__container'>
            <List>                
                <ListItem 
                    disableGutters 
                    button 
                    onClick={(e) => switchActiveItem(e, 'providers')}
                >
                    <ListItemIcon className='drawer-icon__container'>
                        <FontAwesomeIcon className='drawer-icon' icon={['fal', 'chart-network']} size='lg' />
                    </ListItemIcon>
                    <span className='sidebar-tab-title'>Providers</span>
                </ListItem>
                <ListItem 
                    disableGutters 
                    button 
                    onClick={(e) => switchActiveItem(e, 'reports')}
                >
                    <ListItemIcon className='drawer-icon__container'>
                        <FontAwesomeIcon className='drawer-icon' icon={['fal', 'tachometer-alt-fastest']} size='lg' />
                    </ListItemIcon>
                    <span className='sidebar-tab-title'>Reports</span>
                </ListItem>               
                <ListItem 
                    disableGutters 
                    button 
                    onClick={(e) => switchActiveItem(e, 'health')}
                >
                    <ListItemIcon className='drawer-icon__container'>
                        <FontAwesomeIcon className='drawer-icon' icon={['fal', 'heart-rate']} size='lg' />
                    </ListItemIcon>
                    <span className='sidebar-tab-title'>Health</span>
                </ListItem>               
            </List>
            <Divider />
            <List>
                <ListItem 
                    disableGutters 
                    button 
                    onClick={logout}
                >
                    <ListItemIcon className='drawer-icon__container'>
                        <FontAwesomeIcon className='drawer-icon' icon={['fal', 'sign-out']} size='lg' />
                    </ListItemIcon>
                    <span className='sidebar-tab-title'>LOGOUT</span>
                </ListItem>
            </List>
        </div>
    );
};

export default DrawerItems;