const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    headerBar: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        borderBottom: 'solid 1px rgb(0, 0, 0)',
        marginTop: '30px',
    },
    title: {
        fontSize: '2rem',
        paddingLeft: '20px',
        fontWeight: '200',
    },
    providerList: {
        margin: '20px'
    },
    leaseProvider: {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'flex-end',
        height: '80px',
        marginTop: '10px'
    },
    providerImg: {
        width: '250px',
        padding: '10px 20px',
        borderRadius: '5px',
        marginTop: '20px',
        maxHeight: '80px'
    },
    providerImgSm: {
        width: '150px',
        padding: '5px',
        borderRadius: '5px',
        marginTop: '10px'
    },
    valueInput: {
        marginLeft: '20px'
    },
    submitButton: {
        width: '100px',
        height: '40px',
        backgroundColor: 'rgb(50,169,224)',
        color: 'white',
        margin: '40px 0 0 40%'
    },
    hover: {
        cursor: 'pointer',
        backgroundColor: 'rgb(66,150,180)',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    loadingProviders: {
        width: '400px',
        height: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export default styles;