const initialAppState = {
    addToRoute: null,
    programId: null,
    activeOffer: null,
    activeProgram: null
};

const appStateReducer = (state, action) => {

    switch (action.type) {
// Edit Program
        case 'SHOW_EDIT_PROGRAM':
            return {
                ...state,
                activeProgram: action.payload
            };
        
        case 'HIDE_EDIT_PROGRAM':
            return {
                ...state,
                activeProgram: null
            };
            
// <<-------------------------------------------- OFFERS -------------------------------------------->>
// Add Offer 
        case 'SHOW_ADD_OFFER':
            return {
                ...state,
                addToRoute: action.payload.route,
                programId: action.payload.id
            };
        
// Edit Offer   
        case 'SHOW_EDIT_OFFER':
            return {
                ...state,
                addToRoute: action.payload.route,
                programId: action.payload.id,
                activeOffer: action.payload.offer
            };
            
        case 'HIDE_EDIT_OFFER':
            return {
                ...state,
                activeOffer: null,
                showEditOffer: false
			};

		default:
			return {...state};
    }
};

export {
    initialAppState,
    appStateReducer
};