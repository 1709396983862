const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    headerBar: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        borderBottom: 'solid 1px rgb(0, 0, 0)',
        marginTop: '30px',
    },
    providerBar: {
        width: '80%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        borderBottom: 'solid 1px rgb(0, 0, 0)',
        marginTop: '30px',
    },
    title: {
        fontSize: '2rem',
        paddingLeft: '20px',
        fontWeight: '200',
    },
    providerTitle: {
        fontSize: '1.4rem',
        paddingLeft: '20px',
        fontWeight: 'bold',
        textTransform: 'capitalize'
    },
    providerContainer: {
        width: '100%', 
        height: '100%',
        margin: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    graphContainer: {
        width: '100%', 
        height: '50vh',
        marginTop: '20px',
    },
    dateInputs: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    bucketInput: {
        width: '200px'
    },
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'fle-start',
        backgroundColor: '#f5f5f5',
        padding: '10px',
        borderRadius: '10px',
    },
    tooltipData: {
        fontSize: '0.8rem',
        fontWeight: 'bold'
    }
};

export default styles;