import gql from 'graphql-tag';

export const SET_PROVIDER_LIST = gql`
    mutation setProviderSelection($providerList: ProviderList) {
        setProviderSelection(providerList: $providerList) {
            success
            statusCode
            message
        }
    }
`;