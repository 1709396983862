import React, { useState, useContext } from 'react';
import { AppContext } from '../../context';
import { useMutation } from '@apollo/client';
import { UPDATE_PROGRAM, DELETE_PROGRAM } from '../../utilities/mutations';
import { PROGRAMS } from '../../utilities/queries';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from 'react-toast-notifications';

const EditProgram = () => {
	let history = useHistory();
	const { addToast } = useToasts();
	const { appState, dispatchApp } = useContext(AppContext);
	const blankPid = { pid: '' };
	
	const pidValues = appState.activeProgram ? appState.activeProgram.pid.map((value) => ({ pid: value })) : [blankPid];
	const [pidState, setPidState] = useState(pidValues);

	const handleRedirect = (data) => {
		if (data.updateProgram && data.updateProgram.success) {
			addToast('Successfully UPDATED program!', { appearance: 'success', autoDismiss: true });
			history.push('/offers');
			return;
		}
		if (data.deleteProgram && data.deleteProgram.success) {
			addToast('Successfully DELETED program!', { appearance: 'success', autoDismiss: true });
			history.push('/offers');
			return;
		}
		addToast('Failed to EDIT program!', { appearance: 'error', autoDismiss: true });
		history.push('/offers');
	};

	const [updateProgram] = useMutation(
		UPDATE_PROGRAM,
		{ onCompleted: handleRedirect }
	);
	
	const [deleteProgram] = useMutation(
		DELETE_PROGRAM,
		{ onCompleted: handleRedirect }
	);
	
	const handleAddInputClick = () => {
        setPidState([...pidState, { ...blankPid }])
    };

    const handleRemoveInputClick = (idx) => {
        let newState = pidState.filter( (el, i) => i !== idx);
        setPidState(newState);
    };

    const handlePidChange = (e) => {
		const updatedPids = [...pidState];
		updatedPids[e.target.getAttribute('data-idx')]['pid'] = e.target.value;
        setPidState(updatedPids);
	};
	
	const handleDelete = (e) => {
		e.preventDefault();
		deleteProgram({
			refetchQueries: [{
				query: PROGRAMS
			}],
			variables: { id: appState.activeProgram.id } 
		});
		dispatchApp({ type: 'HIDE_EDIT_PROGRAM' });
		history.push('/offers');
	};

	const handleCancel = (e) => {
		e.preventDefault();
		dispatchApp({ type: 'HIDE_EDIT_PROGRAM' });
		history.push('/offers');
	};

	const handleSubmit = (e) => {
		const { title, tag, description, avatar } = e.currentTarget.elements;
		e.preventDefault();
		const pidValues = pidState.map((input) => (
            Number(input.pid)
        ));
        const payload = {
			id: appState.activeProgram.id,
			program: {
				id: appState.activeProgram.id,
				title: title.value || 'N/A',
				pid: pidValues || [],
				tag: tag.value || 'N/A',
				description: description.value || 'N/A',
				avatar: avatar.value || 'https://loremflickr.com/100/100'
			}
		};
		updateProgram({
			refetchQueries: [{
				query: PROGRAMS
			}],
			variables: payload 
		});
	};

	const { title, tag, avatar, description } = appState.activeProgram;

	return (
		<div className='mutation__container'>
			<div className='mutation__title'>
				<div className='offers-title-text-main'>{`Edit Program: ${title}`}</div>
			</div>
			<form className='form__card' onSubmit={(e) => handleSubmit(e)}>
				<TextField 
					name='title' 
					className='form__text-input'
					label='Title'
					fullWidth
					margin='normal'
					defaultValue={title}
				/>				
				{
                    pidState.map((val, idx) => {
                    const inputId = `pid-${idx}`;
                    return (
                        <div key={`pid_input-${idx}`} className='form__input-wrapper'>
                            <TextField
                                key={`pid-${idx}`}
                                name={inputId}
								id={inputId}
								inputProps={{ 'data-idx': `${idx}` }}              
                                type='text'                                        
								className='pid'
								label='PID'
                                value={pidState[idx].pid}
                                onChange={handlePidChange}
                            />
                            { idx === pidState.length - 1 ? (
								<FontAwesomeIcon
									icon={['fal', 'plus-circle']}
									size='1x'
									className='add-input-icon'
									onClick={() => handleAddInputClick(idx)}
								/> 
							) : (
                                <FontAwesomeIcon
                                    icon={['fal', 'minus-circle']}
                                    size='1x'
                                    className='remove-input-icon'
                                    onClick={() => handleRemoveInputClick(idx)}
                                /> 
                            )}
                        </div>                      
                    );      
                    })
                }
				<TextField 
					name='tag' 
					className='form__text-input'
					label='Tag'
					fullWidth
					margin='normal'
					defaultValue={tag}
				/>
				<TextField 
					name='avatar' 
					className='form__text-input'
					label='Avatar'
					fullWidth
					margin='normal'
					defaultValue={avatar}
				/>
				<TextField 
					name='description'
					className='form__text-input'
					label='Description'
					fullWidth
					margin='normal'
					defaultValue={description}
				/>
				<ButtonGroup size='large' className='form__buttons'>					
					<Button onClick={(e) => handleDelete(e)} variant='contained' color='secondary'>Delete</Button>
					<Button onClick={(e) => handleCancel(e)} variant='contained'>Cancel</Button>
					<Button type='submit' variant='contained' color='primary'>Save</Button>
				</ButtonGroup>
			</form>
		</div>
	);
}

export default EditProgram;