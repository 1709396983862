import React from 'react';
import  { useAuth0 } from '../../utilities/react-auth0-spa';

const Welcome = () => {
	const { user } = useAuth0();

	const buildUser = () => {
        let name = user.nickname || 'stanger';
        return name.charAt(0).toUpperCase() + name.slice(1);
	};
	
	return (
		<div className='welcome-container'>
			Hello, {buildUser()}.
		</div>
	);
}

export default Welcome;