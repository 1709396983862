import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/client';
import { CREATE_PROGRAM } from '../../utilities/mutations';
import { PROGRAMS } from '../../utilities/queries';
import { useToasts } from 'react-toast-notifications';

const AddProgram = () => {
	let history = useHistory();
	const { addToast } = useToasts();
	const blankPid = { pid: '' };
	const [pidState, setPidState] = useState([{ ...blankPid }]);

	const handleRedirect = (data) => {
		if (data.addProgram.success) {
			addToast('Successfully ADDED program!', { appearance: 'success', autoDismiss: true });
			history.push('/offers');
			return;
		}
		addToast('Failed to ADD program!', { appearance: 'error', autoDismiss: true });
	};

	const [createProgram] = useMutation(
		CREATE_PROGRAM,
		{ onCompleted: handleRedirect }
	);

	const handleAddInputClick = () => {
        setPidState([...pidState, { ...blankPid }])
    };

    const handleRemoveInputClick = (idx) => {
        let newState = pidState.filter( (el, i) => i !== idx);
        setPidState(newState);
    };

    const handlePidChange = (e) => {
		const updatedPids = [...pidState];
		updatedPids[e.target.getAttribute('data-idx')]['pid'] = e.target.value;
        setPidState(updatedPids);
	};
	
	const handleSubmit = (e) => {
		const { title, tag, description, avatar } = e.currentTarget.elements;
		e.preventDefault();
		const pidValues = pidState.map((input) => (
            Number(input.pid)
        ));
		const payload = {
			program: {
				title: title.value || 'N/A',
				pid: pidValues || [],
				tag: tag.value || 'N/A',
				description: description.value || 'N/A',
				avatar: avatar.value || 'https://loremflickr.com/100/100'
			}			
		};
		createProgram({
			refetchQueries: [{
				query: PROGRAMS
			}],
			variables: payload 
		});
	};

	return (
		<div className='mutation__container'>
			<div className='mutation__title'>
				<div className='offers-title-text-main'>Create Program:</div>
			</div>
			<form className='form__card' onSubmit={(e) => handleSubmit(e)}>
				<TextField 
					name='title' 
					className='form__text-input'
					label='Title'
					fullWidth
					margin='normal'
				/>				
				{
                    pidState.map((val, idx) => {
                    const inputId = `pid-${idx}`;
                    return (
                        <div key={`pid_input-${idx}`} className='form__input-wrapper'>
                            <TextField
                                key={`pid-${idx}`}
                                name={inputId}
								id={inputId}
								inputProps={{ 'data-idx': `${idx}` }}              
                                type='text'                                        
								className='pid'
								label='PID'
                                value={pidState[idx].pid}
                                onChange={handlePidChange}
                            />
                            { idx === pidState.length - 1 ? (
								<FontAwesomeIcon
									icon={['fal', 'plus-circle']}
									size='1x'
									className='add-input-icon'
									onClick={() => handleAddInputClick(idx)}
								/> 
							) : (
                                <FontAwesomeIcon
                                    icon={['fal', 'minus-circle']}
                                    size='1x'
                                    className='remove-input-icon'
                                    onClick={() => handleRemoveInputClick(idx)}
                                /> 
                            )}
                        </div>                      
                    );      
                    })
                }
				<TextField 
					name='tag' 
					className='form__text-input'
					label='Tag'
					fullWidth
					margin='normal'
				/>
				<TextField 
					name='avatar' 
					className='form__text-input'
					label='Avatar'
					fullWidth
					margin='normal'
				/>
				<TextField 
					name='description'
					className='form__text-input'
					label='Description'
					fullWidth
					margin='normal'
				/>
				<ButtonGroup className='form__buttons'>					
					<Button onClick={handleRedirect}>Cancel</Button>
					<Button type='submit' size='large' variant='contained' color='primary'>Save</Button>
				</ButtonGroup>
			</form>
		</div>
	);
}

export default AddProgram;