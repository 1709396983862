import React, { useContext } from 'react';
import { AppContext } from '../../context';
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client';
import { CREATE_OFFER } from '../../utilities/mutations';
import { OFFERS } from '../../utilities/queries';
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

const AddOffer = () => {
	const { appState } = useContext(AppContext);
	let history = useHistory();
	const { addToast } = useToasts();

	const handleCancel = (e) => {
		e.preventDefault();
		history.push('/offers');
	};

	const handleRedirect = (data) => {
		if (data.addOffer.success) {
			addToast('Successfully ADDED offer!', { appearance: 'success', autoDismiss: true });
			history.push('/offers');
			return;
		}
		addToast('Failed to ADD offer!', { appearance: 'error', autoDismiss: true });
	};

	const [createOffer] = useMutation(
		CREATE_OFFER,
		{ onCompleted: handleRedirect }
	);

	const handleSubmit = (e) => {
		const { title, layout, didYouKnow, text, link, jumpBehind } = e.currentTarget.elements;
		e.preventDefault();

        const payload = {
			id: appState.programId,
			route: appState.addToRoute,
			offer: {
				title: title.value || 'N/A',
				route: appState.addToRoute,
				layout: {
					title: layout.value,
					didYouKnow: didYouKnow.value || 'N/A',
					text: text.value || 'N/A'
				},
				link: link.value || 'https://buyontrust.info/',
				jumpBehind: jumpBehind.value || 'N/A',
				click_count: 0,
				usage: 0
			}
		};

		createOffer({
			refetchQueries: [{
				query: OFFERS,
				variables: { 
					id: appState.programId,
					route: appState.addToRoute
				}
			}],
			variables: payload 
		});
	};

	if(!appState.programId) {
		history.push('/offers');
	};

	return (
		<div className='mutation__container'>
			<div className='mutation__title'>
				<div className='offers-title-text-main'>{`Add Offer to ${appState.addToRoute}:`}</div>
			</div>
			<form className='form__card' onSubmit={(e) => handleSubmit(e)}>
				<TextField 
					name='title'
					className='form__text-input'
					label='Title'
					fullWidth
					margin='normal'
				/>				
				<TextField 
					name='layout' 
					className='form__text-input'
					label='Page Layout'
					fullWidth
					margin='normal'
				/>
				<TextField 
					name='didYouKnow' 
					className='form__text-input'
					label='Did-You-Know text'
					fullWidth
					margin='normal'
				/>
				<TextField 
					name='text' 
					className='form__text-input'
					label='Description text'
					fullWidth
					margin='normal'
				/>
				<TextField 
					name='link' 
					className='form__text-input'
					label='Link URL'
					fullWidth
					margin='normal'
				/>
				<TextField 
					name='jumpBehind'
					className='form__text-input'
					label='Jump Behind URL'
					fullWidth
					margin='normal'
				/>
				<ButtonGroup className='form__buttons'>					
					<Button onClick={(e) => handleCancel(e)}>Cancel</Button>
					<Button type='submit' size='large' variant='contained' color='primary'>Save</Button>
				</ButtonGroup>
			</form>
		</div>
	);
}

export default AddOffer;