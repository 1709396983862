import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './MarketingSelectors.css.js';
import { useMediaQuery } from 'react-responsive';

const Selector = ({ identity, choices, value, handleChange }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const mainStyle = Object.assign({},
        styles.selectorContainer,
        isMobile && styles.mobileSelectorContainer
    );

    return (
        <div style={mainStyle}>
            <TextField
                id={`${identity}-selector`}
                label={`${identity.toUpperCase()}`}
                select
                value={value}
                onChange={handleChange}
                variant='filled'
                style={styles.selectorInput}
                fullWidth={isMobile}
            >
                {choices.map((option) => (
                    <MenuItem key={`${option}-pid`} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    )
};

export default Selector;