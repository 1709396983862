const styles = {
    mainContainer: {
        display: 'inline-flex'
    },
    mobileMainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%'
    },
    selectorContainer: {
        width: '150px',
        margin: '0 10px'
    },
    mobileSelectorContainer: {
        margin: '10px 0',
        width: '100%'
    },
    selectorInput: {
        width: '100%'
    }
};

export default styles;