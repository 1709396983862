import React, { useState } from 'react';
import styles from './Reports.css.js';
import { useMediaQuery } from 'react-responsive';
import ApprovalAmount from './ApprovalAmount';
import MarketingSelectors from '../../MarketingSelectors';
// import Grid from "@material-ui/core/Grid";
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { FETCH_FULL_REPORT } from './graphql/queries';

const months = ['01','02','03','04','05','06','07','08','09','10','11','12'];

const getDate = (add) => {
    const today = dayjs();
    const year = today.get('year');
    const month = today.get('month');
    const day = today.get('date');
    const dateString = `${year}-${months[month]}-${day + add}`;
    return dateString;
};

const cleanDate = (newDate) => {
    const date = dayjs(newDate);
    const year = date.get('year');
    const month = date.get('month');
    const day = date.get('date');
    const dateString = `${year}-${months[month]}-${day}`;
    console.log('date string:', dateString);
    return dateString;
};

const DataBox = ({ loading, value }) => {
    if (loading) return <div style={styles.dataRowItem}><CircularProgress size={30} thickness={4.5}/></div>;
    return (
        <div style={styles.dataRowItem}>
            {value}
        </div>
    )
};

const Reports = () => {
    const today = getDate(0);
    const tomorrow = getDate(1);
    const [value, setValue] = useState([dayjs(), dayjs().add(1, 'day')]);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(tomorrow);
    const [pidValue, setPidValue] = useState('ALL');
    const [sidValue, setSidValue] = useState('ALL');
    const [oidValue, setOidValue] = useState('ALL');
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const handleChange = (date) => {
        console.log('new:', date);
        const setDate1 = cleanDate(date[0]);
        const setDate2 = cleanDate(date[1]);
        setStartDate(setDate1);
        setEndDate(setDate2);
        setValue(date);
    };

    const ImageContainerStyle = Object.assign({},
        styles.imgContainer,
        isMobile && styles.imgContainerSm
    );
    const ImageStyle = Object.assign({},
        styles.providerImg,
        isMobile && styles.providerImgSm
    );
    const dataRowStyle = Object.assign({},
        styles.dataRow,
        isMobile && styles.dataRowSm
    );
    const inputRowStyle = Object.assign({},
        styles.inputRow,
        isMobile && styles.mobileInputRow
    );
    const titleStyle = Object.assign({},
        styles.title,
        isMobile && styles.mobileTitle
    );
    const headerBarStyle = Object.assign({},
        styles.headerBar,
        isMobile && styles.mobileHeaderBar
    );
    const tableContainerStyle = Object.assign({},
        styles.tableContainer,
        isMobile && styles.mobileTableContainer
    );

    const LeaseProvider = ({name, logoUrl, additionalStyle }) => {
        const queryVars = { 
            start: startDate, 
            end: endDate, 
            provider: name, 
            program: { pid: pidValue, sid: sidValue, oid: oidValue } 
        };
        const { loading, error, data } = useQuery(FETCH_FULL_REPORT, { variables: queryVars });
        
        if(error) {
            console.log('error:', error);
            return <div>Error during Report Fetching</div>;
        };

        const DesktopProvider = () => (
            <div style={styles.leaseProvider}>
                <div style={ImageContainerStyle}>
                    <img alt={name} src={logoUrl} style={{...ImageStyle, ...additionalStyle}} />
                </div>
                <div style={dataRowStyle}>
                    <DataBox loading={loading} value={!!data ? data.fetchFullReport.data.starts : null} />
                    <DataBox loading={loading} value={!!data ? data.fetchFullReport.data.abandoned : null} />
                    <DataBox loading={loading} value={!!data ? data.fetchFullReport.data.declined : null} />
                    <DataBox loading={loading} value={!!data ? data.fetchFullReport.data.approved : null} />
                    <ApprovalAmount 
                        loading={loading}
                        value={!!data ? data.fetchFullReport.data.amount : null}
                        provider={name}
                    />
                </div>
            </div>
        );

        const MobileRow = ({ keyValue, value }) => (
            <div style={styles.mobileDataRow}>
                <div style={styles.mobileKey}>{keyValue}</div>
                <div style={styles.mobileValue}>{value}</div>
            </div>
        );

        const MobileProvider = () => (
            <div style={styles.mobileProvider}>
                <div style={styles.mobileLogoRow}><img alt={name} src={logoUrl} style={{...ImageStyle}} /></div>
                <div style={styles.mobileTable}>
                    {loading ? <CircularProgress size={30} thickness={4.5}/>
                    :
                    <>
                    <MobileRow keyValue='Starts' value={!!data ? data.fetchFullReport.data.starts : null}/>
                    <MobileRow keyValue='Abandoned' value={!!data ? data.fetchFullReport.data.abandoned : null}/>
                    <MobileRow keyValue='Declined' value={!!data ? data.fetchFullReport.data.declined : null}/>
                    <MobileRow keyValue='Approved' value={!!data ? data.fetchFullReport.data.approved : null}/>
                    <MobileRow keyValue='Amount' value={Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', }).format(!!data ? data.fetchFullReport.data.amount : null)}/>
                    </>
                    }
                </div>
            </div>
        );
        
        return isMobile ? <MobileProvider /> : <DesktopProvider />;
    };

    return (
        <div style={styles.main}>
            <div style={headerBarStyle}>
                <div style={titleStyle}>Provider Reports</div>
            </div>
            <div style={styles.providerList}>
                <div style={inputRowStyle}>
                    {!isMobile ? (
                        <>
                        <DesktopDateRangePicker
                            calendars={1}
                            value={value}
                            onAccept={(newValue) => { handleChange(newValue) }}
                            sx={{ml: '20px'}}
                        />
                        <MarketingSelectors 
                            pidValue={pidValue} 
                            setPidValue={setPidValue}
                            sidValue={sidValue} 
                            setSidValue={setSidValue}
                            oidValue={oidValue} 
                            setOidValue={setOidValue}
                        />
                        </>
                    ) : (
                        <>
                        <MarketingSelectors 
                            pidValue={pidValue} 
                            setPidValue={setPidValue}
                            sidValue={sidValue} 
                            setSidValue={setSidValue}
                            oidValue={oidValue} 
                            setOidValue={setOidValue}
                        />
                        <MobileDateRangePicker
                            calendars={1}
                            value={value}
                            onAccept={(newValue) => { handleChange(newValue) }}
                            slots={{ field: SingleInputDateRangeField }}
                            sx={{width: '100%', m: '20px 0'}}
                        />
                        </>
                    )}
                </div>
                <div style={tableContainerStyle}>
                    {!isMobile && <div style={styles.tableHeader}>
                        <div style={ImageContainerStyle}></div>
                        <div style={dataRowStyle}>
                            <div style={styles.dataRowItem}>Application Starts</div>
                            <div style={styles.dataRowItem}>Abandoned</div>
                            <div style={styles.dataRowItem}>Declines</div>
                            <div style={styles.dataRowItem}>Approvals</div>
                            <div style={styles.dataRowItem}>Amount Total</div>
                        </div>
                    </div>}
                    <LeaseProvider 
                        name='paytomorrow'
                        logoUrl='https://ecom.paytomorrow.com/assets/images/logos/PayTomorrow_Logo_light_500px_150px.png'
                        additionalStyle={{ padding: '0' }}
                    />
                    <LeaseProvider 
                        name='bot'
                        logoUrl='https://cdn11.bigcommerce.com/s-90vdngbq7j/images/stencil/300x80/buy-on-trust-logo-new_1583944521__13419.original.png'
                        additionalStyle={{ padding: '10px 0' }}
                    />
                    <LeaseProvider 
                        name='uown'
                        logoUrl='https://uown-stage1-dd.rightandabove.com/logos/uown-logo.svg'
                        additionalStyle={{ padding: '10px 0 0 0'}}
                    />
                    {/* <LeaseProvider 
                        name='westcreek'
                        logoUrl='https://westcreekfin.com/wp-content/uploads/2019/06/Logo.png'
                        additionalStyle={{ backgroundColor: 'rgb(34,38,94)' }}
                    />
                    <LeaseProvider 
                        name='aff'
                        logoUrl='https://streetcredcapital.com/wp-content/uploads/2020/03/AFF_2019_Logo_Stacked_Color.png'
                        additionalStyle={{ backgroundColor: 'rgba(34,38,94,0.25)' }}
                    />
                    <LeaseProvider 
                        name='flex'
                        logoUrl='https://www.sec.gov/Archives/edgar/data/1397047/000121390019012114/ex99-1_img001.jpg'
                        additionalStyle={{ backgroundColor: 'rgba(34,38,94,0.25)' }}
                    /> */}
                </div>
            </div>
        </div>
    )
};

export default Reports;