import gql from 'graphql-tag';

const PROGRAMS = gql`
	query Programs {
		getPrograms {
			data {
				id
				title
				pid
				tag
				description
				avatar
			}
		}
	}
`;

const OFFERS = gql`
	query getOffers($id: ID, $route: String) {
		getOffersByRoute(id: $id, route: $route) {
			success
			statusCode
			message
			data {
				id
				title
				layout {
					title
					didYouKnow
					text
				}
				link
				jumpBehind
				click_count
				usage      
			}
		}
	}
`;

const CONVERSION_REPORT = gql`
	query fetchConversionRatesByProgram($provider: String, $dateStart: String, $dateEnd: String, $bucketSize: Int, $program: ProgramInput) {
		fetchConversionRatesByProgram(provider: $provider, dateStart: $dateStart, dateEnd: $dateEnd, bucketSize: $bucketSize, program: $program) {
			success
			statusCode
			message
			data {
				bucketIdx
				provider
				approvals
				conversionRate
				start
				end
				date
			}
		}
	}
`;
const MARKETING_SUMMARY = gql`
	query fetchAllMarketingPoints {
		fetchAllMarketingPoints {
			success
			statusCode
			message
			data {
				pid
				sid
				oid
			}
		}
	}
`;

export {
	PROGRAMS,
	OFFERS,
	CONVERSION_REPORT,
	MARKETING_SUMMARY
};