import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Reports.css.js';

const ApprovalAmount = ({ loading, value, provider }) => {
    const [ amount, setAmount ] = useState(null)
    
    useEffect(() => {
        if(!!value) {
            if(provider === 'acima') {
                setAmount(Number(value) / 100)
            } else {
                setAmount(Number(value))
            };
        };
// eslint-disable-next-line
    },[value]);

    if (loading) return <div style={styles.dataRowItem}><CircularProgress size={30} thickness={4.5}/></div>;
    
    return (
        <div style={{...styles.dataRowItem, fontWeight: 'bold'}}>
            {Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(amount)}
        </div>
    )
};

export default ApprovalAmount;